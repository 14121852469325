
/* Create a custom checkbox */
.checkmark {
    height: 18px;
    width: 18px;
    color: white;
    background-color: white;
    box-shadow: none;
    margin: 0;
    padding: 0;
    float: left;
    margin-right: 20px;
    margin-top: 2px;
  }

  ul > li{
    margin-bottom: 25px;
  }

  .numStyle {
    padding: 0;
  }

  

