h3, h4 {
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 1.3px;
    font-family: 'proxima-nova', sans-serif;

  }
.fontStyle {
  font-family: 'proxima-nova', sans-serif;
}

/* SimpleTabs Style */

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    margin: 0;
    padding: 0;
    font-family: 'proxima-nova', sans-serif;
    letter-spacing: 0.5px;
  
  }
  .react-tabs__tab-list:first-child {
    text-align: left;
  }
  
  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 2% 11%;
    cursor: pointer;
    font-size: 20px;
    color: #c2c7d1;
    font-family: 'proxima-nova', sans-serif;
    
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: #4ca7ab;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 500px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
    background-color: white;
    font-family: 'proxima-nova', sans-serif;

  }

  .react-tabs__tab-list:first-child {
  
  }

  
@media only screen and (min-width : 320px) {
  
  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 2% 8%;
    cursor: pointer;
    font-size: 20px;
    color: #c2c7d1;
  }
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: #4ca7ab;
    font-weight: 700;
  }
  .MuiExpansionPanelSummary-content-348 > :last-child{
    padding: 0;
  }
}


/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
  
  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 2% 11%;
    cursor: pointer;
    font-size: 20px;
    color: #c2c7d1;
    margin-top: 20px;
  }
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: #4ca7ab;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .react-tabs__tab-list:first-child {
    text-align: left;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    /* your mobile css, change this as you please */
    .react-tabs__tab-list:first-child {
      text-align: left;
    } 
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    /* your desktop css */
   
}

