










.slick-prev:before,
.slick-next:before{
    color: #4ca7ab
}
