body {
  background-color: #fff;
  color: '#585c60';
  margin: 0;
  padding: 0;
  font-family: 'proxima-nova', sans-serif;
  max-height: 60vh;
}

h1 {
  font-family: 'proxima-nova', sans-serif;
}
input[name="inputStyle"] {
  width: 40px;
}

@media only screen and (min-width : 320px) {
  body {
    background-color: #fff;
    height: 80vh;
    clear: both;
    text-align: left;
}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
  body {
    background-color: #fff;
    height: 80vh;
    clear: both;
    text-align: left;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    /* your mobile css, change this as you please */
    body {
      background-color: #fff;
      margin: 0;
      padding: 0;
      font-family: 'proxima-nova', sans-serif;
      max-height: 60vh;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    /* your desktop css */
   
}
/*timepicker style*/
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}


/*home page styles*/

.masthead {
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) ;
}

.masthead.segment {
  min-height: 700px;
  padding: 0 ;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0em;
}

.masthead h1.ui.header {
  margin-top: 3em;
  margin-bottom: 0;
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.footer.segment {
  padding:  0;
}

.secondary.inverted.pointing.menu {
  border: none;
}

/*end home page styles*/


/* navbar styles */

.ui.menu .item img.logo {
  margin-right: 0;
}

.ui.fixed.menu {
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
}

.ui.main.container,
.main.segment {
  margin-top: 7em;
}

.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0em;
}

.ui.menu .item>img:not(.ui) {
  margin-right: 0 ;
}

.ui.menu:not(.vertical) .item>.button {
  margin-left: 0em;
}

/*chat comments*/

.ui.comments .comment .comments {
  padding-bottom: 0 ;
  padding-left: 0 ;
}

/* react-autosuggest-suggestions-style */


.react-autosuggest__container {
  position: relative;
  z-index: 1;

}

.react-autosuggest__input {
  padding: 10px 20px;
  font-family: 'proxima-nova', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

}

.react-autosuggest__suggestions-container--open {
  display: block;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: 'proxima-nova', sans-serif;
  
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 1;

}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  z-index: 1;

}

.Select-menu-outer {
  z-index: 999 !important;
}

input,
    .StripeElement {
      display: block;
      margin: 4px 0 20px 0;
      padding: 7px 10px;
      font-size: 1em;
      font-family: 'proxima-nova', sans-serif;
      border: 1px solid #e1e1e6;
      outline: 0;
      border-radius: 4px;
      background: white;
    }

    input:focus,
    .StripeElement--focus {
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.PaymentRequestButton {
      padding: 0;
    }

    #stripeButton {
      white-space: nowrap;
      border: 0;
      outline: 0;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      color: #fff;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      background-color: #669fff;
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin-top: 10px;
    }

    #stripeButton:hover {
      color: #fff;
      cursor: pointer;
      background-color: #669fff;
      box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
    }
#levels option {
  max-width: 20px;
  text-overflow: ellipsis;
}

